'use client';
import {
  ChannelFilterType,
  ClinicSearchResult,
  DateFilterType,
  FilterProps,
  PaymentFilterType,
  SortingListingType,
  getAlgoliaSearchResponse,
} from '@/api/algolia';
import { MayBeSearchLocation, getDeliveryModeFilterFromQueryParameters, makeSearchParams } from '@/utils/search';
import { getEnumValueFromString, getStringFromQueryParam } from '@/utils/string';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation';
import Paginator from '@/app/_components/paginator';
import Container from '@/app/_components/container';
import { Bars4Icon, MapPinIcon } from '@heroicons/react/24/outline';
import { trackLicensedItemInSearchList } from '@/utils/trackingGtm';
import ListingFilters from './listingFilters';
import { ListingInfo } from './types';
import { convertSearchResponseIntoListingInfo } from '@/utils/listing';
import { getMacroSpeciality } from '@/utils/macroSpecialities';
import dynamic from 'next/dynamic';
import { makeMarkersList } from '@/utils/maps';
import { ELButton, ELLoader } from '@davincihealthcare/elty-design-system-react';
import clsx from 'clsx/lite';
import Image from 'next/image';
import { DAVINCI_CDN } from '@/costants/defaultValues';
import { ListingOrder } from '@/app/_components/widgets/listing/listingOrder';
import { useListingOrder } from '@/app/_hooks/useListingOrder';
import CardV2 from '@/app/_components/widgets/listing/cardV2';
import { useIsTantosvago } from '@/app/_hooks/useIsTantosvago';
import { useIsScreen } from '@/app/_hooks/useIsScreen';
import { getUserLocation } from '@/api/mapbox';

type ListingProps = {
  service?: string;
  locationInfo?: MayBeSearchLocation;
  searchFilters?: FilterProps;
  searchResultsServerSide?: ClinicSearchResult;
  paginationPadding?: 'lg' | 'md';
  filterLight?: boolean;
  showSearchInfo?: boolean;
  setLoadingState?: (isLoading: boolean) => void;
  loadingState?: boolean;
  showSearchNearYouCTA?: boolean;
};

const DynamicMap = dynamic(() => import('@/app/_components/widgets/map/interactive'), { ssr: false });

export const getGeoLocationParams = (
  params: ReadonlyURLSearchParams | null,
  { place, coordinates }: { place: string; coordinates: { lat: number; lng: number } },
) => {
  const newParams = new URLSearchParams(params?.toString());
  newParams.delete('page');
  newParams.set('location', place);
  newParams.set('lat', coordinates.lat?.toString());
  newParams.set('lng', coordinates.lng?.toString());
  newParams.set('userGeolocationActive', 'true');

  return newParams;
};

const Listing = ({
  service,
  locationInfo,
  searchFilters,
  searchResultsServerSide,
  paginationPadding = 'lg',
  filterLight,
  showSearchInfo = true,
  setLoadingState,
  loadingState,
  showSearchNearYouCTA = false,
}: ListingProps) => {
  const isDesktop = !useIsScreen('lg');
  const [listingInfo, setListingInfo] = useState<ListingInfo>(
    searchResultsServerSide ? convertSearchResponseIntoListingInfo(service, locationInfo?.name, searchResultsServerSide) : {},
  );

  const params = useSearchParams();
  const [location, setLocation] = useState<MayBeSearchLocation | undefined>(locationInfo);

  const [isShowingMap, setShowMap] = useState(false);
  const [hasMapLoaded, setMapLoaded] = useState(false);
  useEffect(() => {
    if (!hasMapLoaded && isShowingMap) {
      setMapLoaded(true);
    }
  }, [hasMapLoaded, isShowingMap]);

  const [isShowingSearchNearYou, setIsShowingSearchNearYou] = useState(showSearchNearYouCTA);

  const { orderLabels, orderValuesLoading, setOrderLoading, setOrderValue, resetOrderValues } = useListingOrder();

  const serviceSearchParam = params?.get('service') ?? '';

  const isSpecializationSearch = !!getMacroSpeciality(getStringFromQueryParam(serviceSearchParam));

  const [isSearchNearYouLoading, setIsSearchNearYouLoading] = useState(false);
  const searchNearYou = async () => {
    setIsSearchNearYouLoading(true);
    try {
      const currentLocation = await getUserLocation();
      if (!currentLocation) {
        setIsSearchNearYouLoading(false);
        return;
      }
      setLocation({
        latitude: currentLocation?.userCoordinates?.[0] ? `${currentLocation?.userCoordinates?.[0]}` : '',
        longitude: currentLocation?.userCoordinates?.[1] ? `${currentLocation?.userCoordinates?.[1]}` : '',
        name: currentLocation?.userLocationName,
      });
      scrollToListingStart();
      setIsSearchNearYouLoading(false);
      setIsShowingSearchNearYou(false);
    } catch (error) {
      console.error('Error checking location permission:', error);
      setIsSearchNearYouLoading(false);
      return null;
    }
  };

  const isWelfare = useIsTantosvago();
  const userGeolocationActive = params?.get('userGeolocationActive');
  const isUserGeolocationActive = getStringFromQueryParam(userGeolocationActive ?? undefined) === 'true' ? true : false;

  const serviceSearch = params?.get('service') ?? '';

  const doNewSearch = useCallback(async () => {
    const parsedPage = Number(params?.get('page') ?? '1');
    const currentPage = isNaN(parsedPage) ? 1 : parsedPage;

    const queryServiceFilter = getStringFromQueryParam(params?.get('filterService') ?? '');
    const queryDeliveryFilter = getDeliveryModeFilterFromQueryParameters(params?.get('exclude') ?? '');
    const queryOrderFilter = getEnumValueFromString(SortingListingType, getStringFromQueryParam(params?.get('order') ?? ''));

    setOrderLoading(SortingListingType.priceAsc);
    setOrderLoading(SortingListingType.firstAvailabilityAsc);

    if (queryOrderFilter === SortingListingType.distanceAsc) {
      setOrderLoading(SortingListingType.distanceAsc);
    }

    const channelFilter = params?.get('channelFilter')?.split(',');
    const paymentFilter = getStringFromQueryParam(params?.get('paymentFilter') ?? '');
    const dateFilter = getStringFromQueryParam(params?.get('dateFilter') ?? '');
    const { searchHealthcareService, searchLocation, searchPage, searchFilter, searchOrder } = await makeSearchParams(
      {
        service: serviceSearch,
        lat: params?.get('lat') ?? '',
        lng: params?.get('lng') ?? '',
        page: currentPage,
        location: params?.get('location') ?? '',
      },
      service,
      location,
      {
        ...searchFilters,
        service: queryServiceFilter,
        excludeDomicilary: queryDeliveryFilter?.excludeDomicilary,
        excludeOnline: queryDeliveryFilter?.excludeOnline,
        excludeOnsite: queryDeliveryFilter?.excludeOnsite,
        dateFilter: dateFilter as DateFilterType,
        channel: channelFilter as ChannelFilterType[],
        preferFavorite: isSpecializationSearch,
        paymentType: isWelfare ? PaymentFilterType.online : (paymentFilter as PaymentFilterType),
      },
      queryOrderFilter,
    );
    const searchResponse = await getAlgoliaSearchResponse({
      text: searchHealthcareService,
      searchLocation,
      page: searchPage,
      filterObj: searchFilter,
      order: searchOrder,
    });
    const newListingInfo = convertSearchResponseIntoListingInfo(searchHealthcareService, searchLocation?.name, searchResponse);
    setListingInfo(newListingInfo);
    trackLicensedItemInSearchList(newListingInfo);
    setLoadingState?.(false);

    if (isUserGeolocationActive && (newListingInfo.total ?? 0) <= 0) {
      setShowMap(false);
    }
    if (queryOrderFilter === SortingListingType.distanceAsc && isUserGeolocationActive) {
      setOrderValue(SortingListingType.distanceAsc, searchResponse);
    }
    setOrderValue(SortingListingType.priceAsc, searchResponse);
    setOrderValue(SortingListingType.firstAvailabilityAsc, searchResponse);
  }, [
    params,
    service,
    searchFilters,
    setOrderLoading,
    setOrderValue,
    isSpecializationSearch,
    location,
    isUserGeolocationActive,
    isWelfare,
    setLoadingState,
    serviceSearch,
  ]);

  const listingInfoRef = useRef<HTMLDivElement>(null);

  const scrollToListingStart = () => {
    const offsetTop = 150;
    const targetPosition = listingInfoRef.current?.getBoundingClientRect().top ?? 0;
    const scrollPosition = window.scrollY + targetPosition - offsetTop;

    window.scrollTo({
      top: scrollPosition,
      behavior: 'smooth',
    });
  };

  const setLocationParams = ({ place, coordinates }: { place: string; coordinates: [number, number] }) => {
    if (userGeolocationActive) return;
    const newParams = getGeoLocationParams(params, { place, coordinates: { lat: coordinates[0], lng: coordinates[1] } });
    window.history.pushState(null, '', `?${newParams.toString()}`);
  };

  useEffect(() => {
    if (!showSearchNearYouCTA) window.scrollTo(0, 0);
  }, [listingInfo.results, showSearchNearYouCTA]);

  useEffect(() => {
    doNewSearch();
  }, [doNewSearch]);

  useEffect(() => {
    resetOrderValues();
  }, [locationInfo, serviceSearch, resetOrderValues]);

  return (
    <>
      {isShowingSearchNearYou && (
        <div className="flex items-center justify-center">
          {isSearchNearYouLoading ? (
            <ELLoader />
          ) : (
            <ELButton
              aria-label={'Cerca vicino a te'}
              label={'Cerca vicino a te'}
              variant="filled"
              color="secondary"
              onClick={searchNearYou}
              leadingIcon={<MapPinIcon />}
              size="large"
            />
          )}
        </div>
      )}
      {loadingState && (
        <div className="my-24 flex flex-col items-center gap-4">
          <Image src={`${DAVINCI_CDN}/inclinic/images/waiting.png`} width={600} height={600} alt="waiting" className="max-w-[300px]" />
          <div className="flex flex-col items-center justify-center gap-y-4">
            <h3 className="text-center text-lg font-bold">Ricerca in corso...</h3>
            <ELLoader />
          </div>
        </div>
      )}
      <div className={`${loadingState ? 'hidden' : 'visible relative'}`}>
        <ListingFilters filterLight={filterLight} showMap={() => setShowMap(true)} />
        <Container>
          <div ref={listingInfoRef} className="grid grid-cols-1 lg:grid-cols-[4fr_2fr] lg:gap-x-12">
            <div className="flex flex-col">
              {/* listing header */}
              {listingInfo?.title && (
                <div className="flex items-center justify-between py-4 md:py-6">
                  {showSearchInfo && <p className="text-sm font-normal text-neutral-darker">{listingInfo.title}</p>}
                  <p className="text-sm font-normal text-neutral-lighter md:text-sm">{listingInfo?.total ?? 0} risultati</p>
                </div>
              )}
              {!!listingInfo?.results?.length && <ListingOrder orderValues={orderLabels} loadingOrderValues={orderValuesLoading} />}
              {/* listing body */}
              {!!listingInfo?.results?.length && (
                <div className="grid grid-rows-1">
                  <div className="grid grid-cols-1 items-start gap-y-4">
                    {listingInfo.results.map((item, index) => {
                      return <CardV2 key={item.objectID} item={item} index={index} />;
                    })}
                  </div>
                  <div className={paginationPadding === 'lg' ? `pt-48` : 'pt-10'}>
                    <Paginator totalPages={listingInfo?.pages} />
                  </div>
                </div>
              )}
            </div>
            {/* map */}
            {(!!makeMarkersList(listingInfo?.results ?? [])?.length || isUserGeolocationActive) && !isDesktop ? (
              <>
                {/* mobile/tablet */}
                {hasMapLoaded && (
                  <div className={clsx('block', !isShowingMap && 'hidden')}>
                    <div className="absolute inset-0 -left-2 -top-2 z-[90] h-full">
                      <div className="h-screen w-full">
                        <DynamicMap
                          items={listingInfo.results ?? []}
                          setUserGeolocation={setLocationParams}
                          isUserGeolocationActive={!!userGeolocationActive}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {/* desktop */}
                <div className="lg:relative lg:flex lg:min-h-full lg:w-full">
                  <div className="sticky top-0.5 h-screen w-full">
                    <div className="h-full w-full">
                      <DynamicMap
                        items={listingInfo.results ?? []}
                        setUserGeolocation={setLocationParams}
                        isUserGeolocationActive={!!userGeolocationActive}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Container>

        <div className={clsx('xl:hidden', !!makeMarkersList(listingInfo?.results ?? [])?.length ? 'relative' : 'hidden')}>
          {isShowingMap && (
            <div className="fixed bottom-10 z-[1000] flex w-full justify-center">
              <ELButton
                aria-label={'Mostra lista risultati'}
                label={'Lista'}
                variant="filled"
                color="secondary"
                onClick={() => {
                  scrollToListingStart();
                  setShowMap(!isShowingMap);
                }}
                leadingIcon={<Bars4Icon />}
                size="medium"
                float
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Listing;
